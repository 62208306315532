@use '../_colors.scss';
@use '../dimensions';
@use '@material/button';

.services#action-show,
.services#action-index {
  .organization-rating {
    label {
      cursor: pointer;
    }

    .rating-delete {
      vertical-align: middle;
      cursor: pointer;
      margin-left: 12px;
    }
  }
}

.services#action-index {
  .services-title {
    width: 100%;

    h1 {
      text-align: center;
      font-weight: bold;
      margin-top: 0;
    }
  }
}

.services#action-show {
  @media (min-width: dimensions.$desktop-width-min) {
    main {
      background-color: #ddd;
      padding-bottom: 36px;
    }

    #body-content {
      background-color: white;
      padding: 0;

      #back-to-search-results {
        background-color: #ddd;
        padding-bottom: 24px;
      }
    }
  }

  h1 {
    font-weight: bold;
    text-align: center;
    margin-top: 0;
  }

  .badges-wrapper {
    @media (min-width: dimensions.$desktop-width-min) {
      display: flex;
      justify-content: center;
    }
  }

  .badges {
    @media (max-width: dimensions.$tablet-width-max) {
      max-width: 450px;
      margin: 0 auto;
    }

    @media (min-width: dimensions.$desktop-width-min) {
      width: 780px;
      padding-left: 24px;
    }

    .mdc-tooltip-wrapper--rich {
      display: inline;

      .badge-button {
        border: none;
        background: none;
        padding: 0;
        cursor: pointer;
      }
    }
  }

  #organization {
    display: flex;
    justify-content: center;

    @media (max-width: dimensions.$tablet-width-max) {
      flex-direction: column;
      max-width: 450px;
      margin: 0 auto;
    }

    #about {
      @media (min-width: dimensions.$desktop-width-min) {
        width: 301px;
        padding-left: 24px;
        padding-right: 24px;
      }

      .organization-rating {
        margin-bottom: 24px;
      }

      #social-media {
        margin-bottom: 24px;

        a {
          width: 42px;
          text-align: center;
          display: inline-block;
        }
      }

      .info {
        display: flex;
        margin-bottom: 24px;

        .icon {
          width: 42px;
          text-align: center;
        }

        .email,
        .site-address,
        .website-url {
          max-width: 258px;
          overflow-wrap: break-word;
        }

        p {
          margin: 0;
        }
      }
    }

    #categories {
      @media (min-width: dimensions.$desktop-width-min) {
        width: 410px;
        padding-left: 24px;
        padding-right: 24px;
        border-left: 1px solid #aaa;
      }

      li {
        list-style-type: circle;
      }

      .accordion {
        overflow: hidden;

        .tab {
          overflow: hidden;

          input.tab-toggle {
            position: absolute;
            opacity: 0;
            z-index: -1;
          }

          input.tab-toggle:checked {
            ~ .tab-label {
              .accordion-button {
                transform: rotate(-180deg);
                transition: all 0.5s;
              }
            }

            ~ .tab-content {
              max-height: 100em;
              transition: all 2s;
              // https://www.drupal.org/project/flexslider/issues/1748514
              -webkit-transform: translate3d(0px, 0px, 1px);
            }
          }

          &-label {
            display: flex;
            justify-content: flex-start;
            padding-left: 4px;
            align-items: center;

            .accordion-button {
              line-height: 2em;
              transform-origin: 0.3em 1em;
              transition: all 0.5s;
              cursor: pointer;
            }
          }

          &-content {
            max-height: 0;
            padding: 0 1em;
            transition: all 0.5s;
            overflow: hidden;
          }
        }

        .tab-content {
          padding: 0;
        }

        .accordion-label,
        .accordion-static-label {
          margin-left: 10px;
          font-size: 1.15rem;
          font-weight: bold;
        }

        .accordion-label {
          cursor: pointer;
        }

        .accordion-static-label {
          margin-left: 30.5px;
        }
      }
    }
  }

  #contact-and-update {
    text-align: center;
    margin-top: 36px;

    .mdc-button {
      @include button.filled-accessible(#fc009b);
    }
  }
}
