@use './_colors.scss';
@use './dimensions';

.login-page {
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 40%;
    @media (max-width: dimensions.$mobile-width-max) {
      width: 100%;
    }
  }

  .login-content {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 32px;

    @media (max-width: dimensions.$mobile-width-max) {
      width: 100%;
    }

    h1 {
      font-size: 2rem;
      font-weight: bold;
      margin: 0;
      align-self: flex-start;
    }

    form {
      text-align: center;
      max-width: 100%;
      width: 100%;

      @media (max-width: dimensions.$mobile-width-max) {
        max-width: 100%;
      }

      .login-password-container {
        display: flex;

        .login-password {
          display: flex;
          flex-direction: column;
          width: 100%;
        }

        .password-toggle-icon {
          margin-left: 8px;
          margin-top: 24px;
          padding-left: 18px;
          padding-top: 17px;
          width: 38px;
          height: 37px;
          border: 1px solid rgba(0, 0, 0, 0.38);
          border-radius: 5px;

          i {
            padding-right: 15px;
          }
        }
      }

      .login-button {
        background-color: colors.$complementary;
        margin-bottom: 2rem;
      }
    }

    .login-links {
      align-self: flex-start;
      text-align: left;
    }
  }
}
