@use '../_colors.scss';
@use '../dimensions';

#action-new.contact_us {
  .contact-us-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    form {
      margin-top: 16px;
      padding: 32px;
      background-color: colors.$secondary;
      width: 60%;
      max-width: none;
      border-radius: 8px;

      h1 {
        font-weight: bold;
        margin-top: 0;
        text-align: center;
        color: white;
      }

      .contact-button-container {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 24px;

        .contact-button {
          padding-left: 24px;
          padding-right: 24px;
        }
      }

      @media (max-width: dimensions.$tablet-width-max) {
        width: 80%;
      }

      @media (max-width: dimensions.$mobile-width-max) {
        width: 100%;
        padding: 16px;

        h1 {
          margin-top: 16px;
        }

        .contact-button-container {
          margin-top: 16px;
        }
      }
    }
  }
}
