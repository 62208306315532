@use '../dimensions';

body.hiv_informations {
  #body-content {
    text-align: center;

    h1 {
      font-weight: bold;
      margin: 0;
    }

    img {
      @media (min-width: dimensions.$tablet-width-min) {
        width: 530px;
      }

      @media (max-width: dimensions.$mobile-width-max) {
        width: 100%;
      }
    }
  }
}
