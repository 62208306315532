@use '../_colors.scss';
@use '../dimensions';
@use '@material/button';

.services#action-index {
  #body-content {
    max-width: 100%;

    #organizations-search {
      #show-filters-button {
        display: none;
      }

      #organizations-filters-and-map {
        display: flex;

        #category-filters {
          width: 372px;
          margin-right: 32px;

          .search-warning {
            background-color: #fce7e8;
            display: flex;
            padding: 8px;
            align-items: baseline;
            margin-top: 16px;

            i {
              color: red;
            }

            p {
              margin: 0;
              margin-left: 8px;
            }
          }
        }

        .category,
        .subcategory {
          display: flex;
          justify-content: space-between;

          .mdc-fab--mini {
            width: 32px;
            height: 32px;

            .mdc-fab__icon {
              font-size: 18px;
            }
          }

          .mdc-tooltip--rich {
            top: -90px !important;
            left: -212px !important;
            max-width: 270px;
          }
        }

        .accordion {
          padding-left: 24px;

          .tab {
            &-content {
              max-height: 0;
              padding: 0 1em;
              transition: all 0.5s;
              overflow: hidden;

              &.expanded {
                max-height: 100em;
                transition: all 2s;
                // https://www.drupal.org/project/flexslider/issues/1748514
                -webkit-transform: translate3d(0px, 0px, 1px);
              }
            }
          }
        }

        #apply-filters-button {
          @media (min-width: dimensions.$desktop-width-min) {
            display: none;
          }
        }

        #organizations-map {
          width: 50%;
          min-width: 600px;
          height: 600px;
          border-radius: 5px;
          margin-left: auto;
          margin-right: auto;

          @media (max-width: dimensions.$tablet-width-max) {
            display: none;
          }
        }
      }

      h2 {
        text-align: center;
        font-weight: bold;
      }

      #organizations-list {
        @media (max-width: dimensions.$tablet-width-max) {
          column-count: 1;
        }

        @media (min-width: dimensions.$desktop-width-min) {
          column-count: 3;
        }

        .organization-item {
          break-inside: avoid-column;
          margin-bottom: 48px;
          padding-left: 4px;
          height: 355px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          @media (max-width: dimensions.$mobile-width-max) {
            height: auto;
          }

          &:hover {
            background-color: #eee;
          }

          h3 {
            margin-block-start: 0;
            font-weight: bold;
            margin-bottom: 8px;
          }

          .badges {
            @media (max-width: dimensions.$tablet-width-max) {
              max-width: 450px;
              margin: 0;
            }

            @media (min-width: dimensions.$desktop-width-min) {
              width: 780px;
            }

            @media (max-width: dimensions.$mobile-width-max) {
              margin-bottom: 32px;
            }

            .mdc-tooltip-wrapper--rich {
              display: inline;

              .badge-button {
                border: none;
                background: none;
                padding: 0;
                cursor: pointer;
              }
            }
          }

          .organization-short-hours {
            height: 4.5rem;
            margin-bottom: 1rem;

            @media (max-width: dimensions.$mobile-width-max) {
              height: auto;
            }
          }

          .mdc-button {
            @include button.filled-accessible(colors.$secondary);
          }

          .services-rating {
            margin-bottom: 32px;
          }
        }
      }
    }
  }
}
