@use '../_colors.scss';
@use '../dimensions';

#action-index.events {
  background-color: colors.$background;

  h1 {
    font-weight: bold;
    text-align: center;
    margin-top: 0;
    margin-bottom: 0;
  }

  .events-description {
    text-align: center;

    p {
      color: #333;
      overflow-wrap: break-word;
    }
  }

  p {
    color: #333;
    overflow-wrap: break-word;
  }

  .filters {
    a {
      margin-right: 1em;
      border: 2px solid colors.$primary;
      background-color: transparent;

      .mdc-button__label {
        color: black;
      }
    }

    .with-background {
      background-color: colors.$primary;
    }
  }

  .events-cards {
    .card-row {
      display: flex;

      @media (max-width: dimensions.$mobile-width-max) {
        flex-direction: column;
      }

      .mdc-card {
        width: 33%;
        margin: 5px;
        padding: 10px;
        min-height: 150px;

        @media (max-width: dimensions.$mobile-width-max) {
          width: 100%;
        }

        .mdc-card__primary-action {
          text-align: center;
          padding-bottom: 10px;
        }

        .mdc-card__content {
          min-height: 100px;
          border-bottom: 2px solid colors.$primary;
          border-radius: 0;

          .event-title {
            margin-top: 0;
            margin-bottom: 5px;
            font-weight: bold;
            color: black;
            font-size: 18px;
          }

          .event-time {
            margin-top: 0;
          }

          .event-description {
            color: black;
            font-weight: 500;
            word-wrap: break-word;
          }

          .more {
            padding: 15px;
            color: black;
            font-weight: 500;
          }
        }
      }
    }
  }
}
