@use '../_colors.scss';
@use '../dimensions';

.participant-site-content {
  min-height: 82.6vh;
}

.ptp-navbar {
  .mdc-top-app-bar__title {
    margin-top: 0.5rem;
    margin-right: 1rem;
    padding-left: 5px;
  }

  .ptp-navbar-link {
    background-color: white;
  }

  .active-navbar-link {
    color: colors.$tertiary;
  }

  .ptp-topnav {
    margin-right: 4px;
  }

  @media (max-width: dimensions.$tablet-width-max) {
    .ptp-topnav {
      display: none;
    }
  }

  @media (min-width: dimensions.$desktop-width-min) {
    #mobilenav {
      display: none;
    }

    .mobile-unsigned-nav {
      display: none;
    }

    .mdc-button--raised {
      padding-left: 4px;
      padding-right: 4px;
    }
  }
}

.mdc-drawer__content .mdc-list {
  display: flex;
  flex-direction: column;
  text-align: left;

  .mdc-touch-target-wrapper {
    margin-left: 0.5rem;
  }
}

.ptp-footer {
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: absolute;
  width: 100%;
  padding: 1rem 0;
  background-color: colors.$secondary;

  .footer-section {
    display: flex;
    flex-direction: column;
    width: 33%;
    text-align: center;

    p {
      margin: 0;
      color: white !important;
      font-weight: bold;
    }

    a {
      color: white;
      font-weight: bold;
      text-decoration: underline;
    }
  }
}
