@use '../_colors.scss';
@use '../dimensions';

#action-index.abouts {
  background-color: colors.$background;

  h1 {
    font-weight: bold;
    text-align: center;
    margin-top: 0;
  }

  .ptp-about-section {
    background-color: white;
    padding: 2rem;
    margin-bottom: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    h2 {
      color: colors.$secondary;
      font-size: x-large;
      font-weight: bold;
      text-transform: uppercase;
      text-align: center;
      margin: 0;
    }

    h3 {
      font-size: large;
      text-align: center;
    }

    p {
      margin-bottom: 0;
    }

    .hide-on-mobile {
      display: initial;
    }

    .about-button {
      display: none;
    }

    .badges-container {
      display: flex;
      justify-content: space-between;
      margin-top: 24px;

      .badge-box {
        width: 28%;
        display: flex;
        flex-direction: column;
        align-items: center;
        border: 2px solid colors.$primary;
        padding: 16px;

        img {
          margin-bottom: 16px;
        }
      }
    }
  }

  @media (min-width: dimensions.$desktop-width-min) {
    .about-half-section {
      display: flex;

      .ptp-about-section {
        display: flex;
        flex-direction: column;
        width: 45%;

        h2 {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .ptp-about-section:first-of-type {
        margin-right: 1.5rem;
      }
    }
  }

  @media (max-width: dimensions.$tablet-width-max) {
    .ptp-about-section {
      border: 2px solid colors.$primary;

      .hide-on-mobile {
        display: none !important;
      }

      .about-button {
        display: initial !important;
        background-color: colors.$tertiary;
        color: white;
        margin-top: 1rem;
      }

      .badges-container {
        flex-direction: column;
        width: 100%;
        align-items: center;

        .badge-box {
          width: 65%;
          border: 2px solid colors.$tertiary;
          margin-bottom: 24px;
          text-align: center;
        }
      }
    }
  }

  @media (max-width: dimensions.$mobile-width-max) {
    .ptp-about-section {
      .badges-container {
        .badge-box {
          width: 90%;
        }
      }
    }
  }
}
