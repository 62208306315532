@use '../_colors.scss';
@use '../dimensions';

#action-show.homes {
  text-align: center;

  .homepage-welcome {
    width: 70%;
    margin: auto;

    img {
      width: 60%;
    }

    h1 {
      font-size: 24px;
      font-weight: bold;
      line-height: 2.25rem;
      margin-top: 32px;
    }
  }

  .homepage-box-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: auto;

    .homepage-box {
      display: grid;
      grid-template-columns: 100%;
      grid-template-rows: 20% 15% 55% 10%;
      width: 30%;
      padding: 1rem;
      padding-bottom: 1.5rem;
      margin: 1rem 0.5rem;
      justify-items: center;
      background-color: white;

      i {
        scale: 2;
        color: white;
        padding: 0.35rem;
        border-radius: 15px;
        margin: 1rem;
        margin-top: 1.5rem;
        grid-row: 1;
        height: fit-content;
      }

      .fa-compass {
        background-color: colors.$secondary;
      }

      .fa-mug-hot {
        padding-left: 8px;
        background-color: colors.$tertiary;
      }

      .fa-face-smile {
        background-color: colors.$complementary;
      }

      h2 {
        font-weight: bold;
        font-size: larger;
        margin-bottom: 0;
        grid-row: 2;
      }

      p {
        grid-row: 3;
      }

      .mdc-touch-target-wrapper {
        grid-row: 4;
        margin-top: 1rem;
        align-self: flex-end;
      }
    }
  }

  .homepage-links {
    h2 {
      font-size: xx-large;
      font-weight: bold;
    }

    .homepage-link-container {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;

      .homepage-link {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: white;
        padding: 1.5rem;
        border: 5px solid colors.$primary;
        border-radius: 5px;
        color: black;
        font-size: larger;
        font-weight: bold;
        width: 25%;
        height: 2.5rem;
        margin-bottom: 1.5rem;
      }
    }
  }

  @media (max-width: dimensions.$tablet-width-max) {
    .homepage-welcome {
      width: 100%;

      img {
        width: 50%;
      }
    }

    .homepage-box-container {
      flex-direction: column;
      align-items: center;

      .homepage-box {
        width: 50%;
      }
    }

    .homepage-links .homepage-link-container .homepage-link {
      width: 40%;
    }
  }

  @media (max-width: dimensions.$mobile-width-max) {
    .homepage-box-container {
      width: 100%;
      .homepage-box {
        width: 80%;
      }
    }
    .homepage-links .homepage-link-container .homepage-link {
      width: 100%;
    }
  }
}
